<template>
  <div class="navmenu">
      <!-- {{navmenu}} -->
      <p @click="jumpClassify(navmenu,0)">{{navmenu.name}} </p>
       <el-divider></el-divider>
      <ul class="left_nav">
      <li v-for="(item,index) in navmenu.child" :key="index" @click="jumpClassify(item,1)"> 
        <span>{{item.name}} </span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
   name: "NavMenu",
  data() {
    return {
    };
  },
  props: ['navmenu'],
  methods: {
    
    jumpClassify(item,key) {
      if(key==0){
          const newTooltip=[];
          localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      }else{
          const newTooltip=[{"name":item.name,"show":true,"id":item.id}];
          localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      }
      localStorage.setItem('clickVal',this.navmenu.name);
      localStorage.setItem('clickID',this.navmenu.id);
      this.$router.push({path: "/productcategory", query: { ids: item.id } });
      this.$emit("hideFun",false)
      
    }
  },
  mounted() {
  },
};
</script>

<style scoped lang="less">
.navmenu{
  p{
    font-size: 16px;
    color: #333333FF;
    cursor: pointer;
     &:hover{
                color: #FFD303;
            }
  }
  .el-divider--horizontal{
    margin:16px 0 6px 0;
  }
  ul{
    li{
      width: 25%;
      float: left;
      text-align: center;
      color:#666666FF;
      font-size: 12px;
      line-height: 40px;
       cursor: pointer;
            &:hover{
                color: #FFD303;
            }
    }
    li:nth-child(4n){
      text-align: right;
    }
    li:nth-child(4n+1){
      text-align: left;
    }
  }
}
</style>
